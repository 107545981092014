import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Style from "./ContentsCard.module.scss";
import Card from "react-bootstrap/Card";
import ROUTES from "../../constants/routes";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ContentsCard: React.FC = () => {
  // 複数のカードを参照するための配列
  const cardRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    // GSAPアニメーションの初期化
    cardRefs.current.forEach((card) => {
      gsap.fromTo(
        card,
        { opacity: 0, y: 50, scale: 0.9 },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 0.8,
          ease: "power3.out",
          scrollTrigger: {
            trigger: card, // カードごとにトリガー
            start: "top 80%", // ビューポートの80%の位置で開始
            end: "top 50%", // ビューポートの50%の位置で終了
            toggleActions: "play none none reverse", // 再生・逆再生
          },
        }
      );
    });
  }, []);

  return (
    <div className={Style.contents}>
      <div className={Style.container}>
        <div
          className={Style.card}
          ref={(el) => el && cardRefs.current.push(el)} // カードを参照リストに追加
        >
          <Card>
            <Card.Header>New</Card.Header>
            <Card.Body>
              <Card.Title>画像ファイルの変換サービス</Card.Title>
              <Card.Text className="mt-3">
                画像ファイルをサーバにアップロードせずにローカル環境（Webブラウザ）で画像の変換を致します。
              </Card.Text>
              <Card.Text className="mb-4">
                これにより、お客さまのプライバシーを守ることができ安全に画像の変換を行えます。
              </Card.Text>
              <div className={Style.servicebtnLink}>
                <Link to={ROUTES.IMAGE_CONVERSION}>View More.</Link>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div
          className={Style.card}
          ref={(el) => el && cardRefs.current.push(el)}
        >
          <Card>
            <Card.Header>New</Card.Header>
            <Card.Body>
              <Card.Title>動画ファイルの変換サービス</Card.Title>
              <Card.Text className="mt-3">
                動画ファイルをサーバにアップロードせずにローカル環境（Webブラウザ）で動画の変換を致します。
              </Card.Text>
              <Card.Text className="mb-4">
                これにより、お客さまのプライバシーを守ることができ安全に動画の変換を行えます。
              </Card.Text>
              <div className={Style.servicebtnLink}>
                <Link to={ROUTES.VIDEO_CONVERSION}>View More.</Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className={Style.container2}>
        <div
          className={Style.card}
          ref={(el) => el && cardRefs.current.push(el)}
        >
          <Card>
            <Card.Header>New</Card.Header>
            <Card.Body>
              <Card.Title>音楽ファイルの変換サービス</Card.Title>
              <Card.Text className="mt-3">
                音楽ファイルをサーバにアップロードせずにローカル環境（Webブラウザ）で音声の変換を致します。
              </Card.Text>
              <Card.Text className="mb-4">
                これにより、著作権法の私的複製を守ることができ安全に音楽の変換を行えます。
              </Card.Text>
              <div className={Style.servicebtnLink}>
                <Link to={ROUTES.MUSIC_CONVERSION}>View More.</Link>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div
          className={Style.card}
          ref={(el) => el && cardRefs.current.push(el)}
        >
          <Card>
            <Card.Header>New</Card.Header>
            <Card.Body>
              <Card.Title>Excelファイルから画像の抽出</Card.Title>
              <Card.Text className="mt-3">
                WebブラウザにExcelファイルをドロップするだけで使用されている画像をまとめてダウンロードできます。
              </Card.Text>
              <Card.Text className="mb-4">
                Excelファイルをサーバにアップロードせずにローカル環境（Webブラウザ）で画像を抽出致します。
              </Card.Text>
              <div className={Style.servicebtnLink}>
                <Link to={ROUTES.EXCEL_OUTPUT_IMAGE}>View More.</Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ContentsCard;
