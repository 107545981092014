import WebGL from "../contents/webGL"; // WebGL コンポーネントをインポート
import styles from "./Hero.module.scss";
import React, { useEffect, useState } from "react";
import animeLogo from "../../svg/animeLogo.svg";

const Hero = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 }); // マウス位置を管理

  useEffect(() => {
    // マウス位置を追跡するイベントリスナー
    const handleMouseMove = (event: MouseEvent) => {
      setPosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="webGl" style={{ position: "relative", width: "98vw" }}>
      <WebGL />
      <div
        className={styles.cursor} // マウスポインターを追随する要素
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
      />
      <div className={styles.hero}>
        <p className={styles.p1}>願いは叶う！！</p>
        <p className={styles.p2}>小さな事からはじめよう。</p>
        <div id={styles.animeLogo}>
          <img src={animeLogo} alt="Logo" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
